.list {
  justify-content: space-between;
}
.item {
  width: fit-content;
  flex: none;
}

.listMargin {
  margin-right: 17px;
}

.listLeft {
  justify-content: flex-start;
}
.mt40 {
  margin-top: 40px;
}
@media (max-width: 960px) {
  .mt40 {
    margin-top: 36px;
  }
}