.coinCol {
    display: flex;
    align-items: center;
}
.coinIcon {
    height: 32px;
    width: 32px;
    margin-right: 12px;
}
.hideCoinIcon {
    display: none;
}
.hide {
    display: none;
}
.coinText {
    display: inline-block;
    line-height: 32px;
}
.coinName {
    font-size: 16px;
}
.currency {
    font-size: 12px;
    color: #8E939F;
}
.latestPriceCol {
    display: flex;
    font-family: 'DINPro';
    font-size: 14px;
}
/* .latestPrice1 {
} */
.latestPrice2 {
    color: #8E939F;
    margin-left: 5px;
}
.increase {
    font-size: 14px;
}
.greenText {
    color: #1EAE45;
}
.redText {
    color: #ED220D;
}

.coinColH5 {
    display: flex;
    align-items: center;
    text-align: left;

}
.coinIconH5 {
    height: 32px;
    width: 32px;
    margin-right: 12px;
}
.coinNameH5 {
    font-size: 16px;
}
.currencyH5 {
    font-size: 12px;
    color: #8E939F;
}
.greyTextH5 {
    color: #8E939F;
    font-size: 12px;
    height: 16px;
    line-height: 16px;
    cursor: default;
}
.transferData {
    margin-left: 5px;
}
.doubleLineH5 {
    height: 37px;
}
.firstLineH5 {
    height: 22px;
    line-height: 22px;
}
.secondLineH5 {
    height: 16px;
    line-height: 16px;
    display: flex
}
.secondLineH5 > div {
    flex: 1;
    white-space: nowrap
}
.latestPriceH5 {
    font-size: 16px;
    display: flex;
    flex-direction: column;
}
.latestPrice1H5 {
    font-size: 16px;
    height: 22px;
    line-height: 22px;
}
.latestPrice2H5 {
    font-family: 'DINPro';
    font-size: 12px;
    color: #8E939F;
    height: 16px;
    line-height: 16px;
}
.riseAndFallH5 {
    min-width: 70px;
}
.flexContainerH5 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0.7;
    align-items: center;
}
.drawerTable :global thead,
.h5Table :global thead {
    display: none;
}
.drawerTable :global td,
.h5Table :global td {
    padding: 15px 8px !important;
}
.drawerTable :global tbody td span,
.h5Table :global tbody td span{
    cursor: default;
}
.h5Table :global .ant-table-tbody > tr {
    cursor: pointer !important;
}
.h5Table {
    display: none !important
}
.displayNone {
    display: none;
}
.lh37 {
    line-height: 37px;
}
.mt12 {
    margin-top: 12px;
}
.rowClassName {
    cursor: pointer;
}
.favoriteIcon {
    margin-right: 9px;
}
.start {
    font-size: 16px;
    /* margin-left: 9px; */
    color: #d9dbdf;
    cursor: pointer !important;
  }


.webTable :global .kiki-button{
    padding: 4px 22px;
}
.webTable :global .ant-table-thead > tr > th{
    line-height: 17px;
    cursor: default;
}
.webTable :global .ant-table-thead > tr > th div{
    line-height: 17px;
    cursor: pointer;
}
.webTable :global .ant-table-tbody > tr {
    cursor: pointer !important;
}
.webTable :global .ant-table-tbody > tr > td  span {
    cursor: default;
}
.webTable :global .ant-table-tbody > tr > td  button > span {
    cursor: pointer;
}


/* .webTable :global .row-dragging{
    background: #fafafa;
    border: 1px solid #ccc;
}
.webTable :global .row-dragging td {
    padding: 16px;
}
.webTable :global .row-dragging .drag-visible {
visibility: visible;
}

.h5Table :global .row-dragging{
    background: #fafafa;
    border: 1px solid #ccc;
}
.h5Table :global .row-dragging td {
    padding: 16px;
}
.h5Table :global .row-dragging .drag-visible {
visibility: visible;
}
.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.row-dragging td > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: red;
} */
.iconSize {
    font-size: 18px;
    cursor: pointer
}
.emptyContain{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -15px;
    /* position: relative; */
}
.emptyImg{
    position: relative;
}
.emptyText{
    position: relative;
    top: -15px;
    color: #696F7F;
}
.addButtom{
    max-width: 280px;
    border-radius: 8px !important;
}

@media (max-width: 960px) {
    .webTable {
        display: none !important;
    }
    .h5Table {
        display: block !important;
    }
}

@media (max-width: 600px) {
    .flexContainerH5 {
        flex: 0.8;
    }
}

@media (max-width: 470px) {
    .flexContainerH5 {
        flex: 1;
    }
}