.text>div{
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-family: DINPro-Bold;
}
.title {
  background: rgba(67, 75, 95, .3);
  backdrop-filter: blur(24px);
}
.title>div>div{
  font-family: DINPro-Bold;
  font-weight: 700;
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis;
}

.imgContent{
  width: 288px
}


@media (max-width: 1240px) {
  .imgContent{
    width: calc(288px * 0.92)
  }
}

@media (max-width: 1220px) {
  .imgContent{
    width: calc(288px * 0.9)
  }
}
@media (max-width: 1120px) {
  .imgContent{
    width: calc(288px * 0.83)
  }
}

@media (max-width: 1040px) {
  .imgContent{
    width: calc(288px * 0.78)
  }
}
@media (max-width: 980px) {
  .imgContent{
    width: calc(288px*0.75)
  }
}

@media (max-width: 960px) {
  .imgContent{
    width: 288px
  }
}