.list {
  justify-content: space-between;
}

.item {
  width: fit-content;
  flex: none;
}

.listMargin {
  margin-right: 17px;
}

.listLeft {
  justify-content: flex-start;
}